import React from "react";

import light from "../../assets/image/l5/jpg/light-thumb.jpg";
import dark from "../../assets/image/l5/jpg/dark-thumb.jpg";
import wallpaper from "../../assets/image/l5/jpg/light-wallpaper-thumb.jpg";

const Media = () => (
  <>
    {/* <!-- CaseStudies section --> */}
    <div class="news-section mt-md-6 pt-24 pt-lg-29">
    <div class="container">
    <div class="row justify-content-center">
      <div class="col-xl-6 col-lg-7 col-md-9">
        <div class="section-title text-center mb-11 mb-lg-18">
          <h2 class="title gr-text-2 mb-9 mb-lg-12">Media Kit</h2>
          <p class="gr-text-8 mb-0">Materi logo Ethical Hacker Indonesia.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://cdn.hack.co.id/zip/light-logo.zip" target="_blank" class="card-img">
            <img src={light} alt="" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://teguh.co/derita-pelanggan-indihome-sudah-bayar-datanya-malah-dicuri/" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Light Logo</p>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://cdn.hack.co.id/zip/dark-logo.zip" target="_blank" class="card-img">
            <img src={dark} alt="" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://cdn.hack.co.id/zip/dark-logo.zip" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Dark Logo</p>
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div class="location-card mb-9 gr-hover-shadow-1">
          <a href="https://cdn.hack.co.id/zip/wallpaper.zip" target="_blank" class="card-img">
            <img src={wallpaper} alt="" class="w-100 rounded-top-10" />
          </a>
          <div class="card-content px-9 py-8 bg-white rounded-bottom-10">
            <a href="https://cdn.hack.co.id/zip/wallpaper.zip" target="_blank">
              <p class="gr-text-11 mb-4 d-inline-block gr-color-blackish-blue-opacity-7">Wallpaper</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  </>
);

export default Media;
