import React from "react";
import PageWrapper from "../components/PageWrapper";
import Media from "../sections/landing/Media";
import { Helmet } from "react-helmet";

const MediaPage = () => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          style: "style1", //style1, style2
        }}
      >
      <Helmet>
        <title>Media Kit - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Materi logo Ethical Hacker Indonesia" />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/media/" />
        <meta property="og:title" content="Media Kit" />
        <meta property="og:description" content="Materi logo Ethical Hacker Indonesia" />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="Media Kit"  />
        <meta name="twitter:description" content="Materi logo Ethical Hacker Indonesia" />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>
        <Media />
      </PageWrapper>
    </>
  );
};
export default MediaPage;
